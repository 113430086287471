.img-background{
    background-image: url(../images/accueil_rubiks.jpg);
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.img-background::before{
    content: '';
    position: absolute;
    width: 102%;
    height: 102%;
    background-color: #FFCD85;
    animation: rotate 5s linear infinite;
    transform: rotate(4deg);
    box-shadow: 11px 19px 79px 0px rgba(0,0,0,0.1);
    border-radius: 26px;
    z-index: -1;
}

@keyframes rotate{
    0%{
        transform: rotate(0deg);
        opacity: 1;
    }
    50%{
        transform: rotate(5deg);
        opacity: 0.5;
    }
    100%{
        transform: rotate(0deg);
        opacity: 1;

    }
}

.developpement{
    @apply py-[5px] px-[18px] bg-white absolute rounded-[20px] text-primOrange font-bold top-[-4%] left-[35%] md:py-2 md:px-6 xl:left-[39%] xl:text-lg;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.expertise{
    @apply py-[5px] px-[18px] bg-white absolute rounded-[20px] text-primOrange font-bold -top-[-40%] right-[-15%] md:py-2 md:px-6 xl:right-[-10%] xl:text-lg;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.integration{
    @apply py-[5px] px-[18px] bg-white absolute rounded-[20px] text-primOrange font-bold top-[76%] left-[-17%] md:py-2 md:px-6 xl:left-[-10%] xl:text-lg;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}


.img-background2{
    background-image: url(../images/fondateurs.JPG);
    background-size: cover;
}

.img-background2::before{
    content: '';
    position: absolute;
    width: 101%;
    height: 101%;
    background-color: #FFCD85;
    animation: rotate 5s linear infinite;
    transform: rotate(4deg);
    border-radius: 26px;
    z-index: -1;
}


.contact-img{
    background-image: url(../images/kairn-modified4.jpg);
}


.img-background3{
    background-size: cover;
}

.img-background3::before{
    content: '';
    position: absolute;
    width: 95%;
    height: 95%;
    background-color: #FFCD85;
    animation: rotate 5s linear infinite;
    transform: rotate(4deg);
    border-radius: 26px;
    z-index: -1;
}

.text-justify{
    text-align: justify!important;
    font-size: 0.9rem!important;
}


.jumping{
    display: inline-block;
    animation: bounce 0.8s infinite;
}

@keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
}
