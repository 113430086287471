@keyframes teapotRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.teapot {
    animation-name: teapotRotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
  
@keyframes wiggle {
    0% {
        transform: rotate(0deg) translateX(0);
    }
    25% {
        transform: rotate(-10deg) translateX(-10px);
    }
    50% {
        transform: rotate(10deg) translateX(10px);
    }
    75% {
        transform: rotate(-10deg) translateX(-10px);
    }
    100% {
        transform: rotate(0deg) translateX(0);
    }
}
  
.teapotText {
    animation-name: wiggle;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg) translateX(0);
    }
    25% {
        transform: rotate(-10deg) translateX(-10px);
    }
    50% {
        transform: rotate(10deg) translateX(10px);
    }
    75% {
        transform: rotate(-10deg) translateX(-10px);
    }
    100% {
        transform: rotate(0deg) translateX(0);
    }
}

.teapotText {
    animation-name: wiggle;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
    

@keyframes colorChange {
    0% {
        color: red;
    }
    50% {
        color: blue;
    }
    100% {
        color: green;
    }
}

@keyframes move {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0);
    }
}

.teapotError {
    animation-name: colorChange, move;
    animation-duration: 5s, 2s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 0s, 1s;
}

@keyframes flip {
    0% {
        transform: perspective(400px) rotateY(0);
    }
    50% {
        transform: perspective(400px) rotateY(180deg);
    }
    100% {
        transform: perspective(400px) rotateY(0);
    }
}
  
.teapotButton {
    animation-name: flip;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}