
.mail-at-container{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

form{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.test{
    background-image: url(../images/blob.svg);
    background-attachment: fixed;
    background-position: top right;
    background-repeat: no-repeat;
}   

.frame{min-height:250px;}


@media (max-width: 767px) { /* Ajout d'une media query pour les écrans de 767px et moins */
    .test {
        background-size: 120vh; /* Changement de la taille de l'image pour qu'elle ne se rétrécisse pas */
        background-image: url(../images/blob-top-r.svg);
        background-position: top right;
        
    }
}


@keyframes neon {
    from {
      opacity: 0.8;
      filter: blur(10px);
    }
    to {
      opacity: 1;
      filter: blur(0px);
    }
  }
  
  .text-lg > span {
    animation: neon 3s linear infinite;
    mix-blend-mode: overlay;
  }