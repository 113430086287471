
body::-webkit-scrollbar {
    /* width: 12px; */
    background-color: #F5F5F5;
    @apply w-1 md:w-2 lg:w-3;
}
    
/* Track */

    
/* Handle */
body::-webkit-scrollbar-thumb {
    background: #FFA500;
    border-radius: 10px;
}
    
/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: #ffc14d;
}