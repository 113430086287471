@tailwind base;
@tailwind components;
@tailwind utilities;

@import "home.css";
@import "contact.css";

/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@layer base {
    body{
        z-index: 1;
    }

    .wrapper{
        @apply  px-4 mx-auto sm:px-10 md:px-20 lg:px-28 xl:max-w-[2000px];
    }
}

@layer components {

    .big-title{
        @apply text-4xl font-bold text-center mb-24 md:text-5xl md:mb-20 text-gray-800;
    }

    .big-title-accordion{
        @apply text-4xl font-bold text-left mb-24 md:text-5xl md:mb-20 text-gray-800;
    }

    .second-title{
        @apply text-3xl lg:text-4xl font-bold text-gray-800 mb-4;
    }

    .paragraph{
        @apply font-normal text-base leading-8 text-gray-600 font-roboto text-justify
    }

    .landing-title{
        @apply text-white font-bold wrapper text-center text-4xl lg:text-6xl mt-8;
    }

    /* Qui sommes-nous ? */

    .nom{
        @apply font-medium text-xl text-gray-800 mt-4 mb-2;
    }

    .cto-container{
        @apply p-4 pb-6 flex justify-center flex-col items-center
    }
    
    .value-bigtitle{
        @apply text-3xl lg:text-4xl font-bold text-gray-800 md:mb-4 lg:mb-12 text-center;
    }

    .value-subtitle{
        @apply text-2xl lg:text-3xl font-bold text-primOrange mb-2 text-left
    }


    /* Header */

    .btn {
        @apply bg-primOrange text-white font-bold text-center rounded-[19px] w-[109px] py-[5px] xl:mr-3 hover:bg-gray-700 transition-all duration-200;
    }

    .item-link:hover {
        @apply transition-colors duration-200 ease-in text-primOrange;
    }

    .item-link{
        @apply my-7 flex items-center relative;
    }

    .item-link-responsive{
        @apply flex items-center justify-center py-8 xl:py-0;
    }

    ul > li{
        @apply flex items-center w-full justify-center xl:w-auto
    }
    
    .item-link::before{
        content: '';
        position: absolute;
        width: 0%;
        height: 2px;
        bottom: -7px;
        left: 0;
        background-color: #FFA500;
        transition: width 0.3s;
    }
    
    .item-link:hover::before{
        width: 100%;
    }

    .item-link:hover::before:focus{
        width: 100%;
        background-color: #FFA500;
    }
}

@layer utilities {
    /* Services */
    .services-box{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        user-select: none;
        transition: all 1s linear;
    }
    
    /* Qui sommes-nous */
    .landing-img{
        background-image: url(../images/kairn.jpg);
        background-size: cover;
        height: 35vh;
        width: 100%;
        filter: grayscale(0.5);
    }

    /* Notre offre */
    .landing-img2{
        background-image: url(../images/kairn2.jpg);
        background-size: cover;
        height: 35vh;
        width: 100%;
    }

    /* Nous rejoindre */
    .landing-img3{
        background-image: url(../images/kairn3.jpg);
        background-size: cover;
        height: 35vh;
        width: 100%;
    }
    
    .ombres{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    
    .decouvrir-img{
        background-image: url(../images/kairn3-modified.jpg);
    }

    /* Nous rejoindre */
    .job-shadow{
        box-shadow: 0px 4px 20px rgba(121, 121, 121, 0.25);
    }
}

.img-pers{
    background-size: cover;
    height: 350px;
    width: 250px;
}

.img-thomas{
    background-image: url(../images/Thomas.jpg);
}

.img-laurent{
    background-image: url(../images/Laurent.jpg);
}

.img-eric{
    background-image: url(../images/Eric.jpg);
}

.img-vincent{
    background-image: url(../images/Vincent.jpg);
}

.img-victor{
    background-image: url(../images/Victor.jpg);
}

.img-malaury{
    background-image: url(../images/Malaury.jpg);
}

.img-pers {
    position: relative;
}
  
.img-pers::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.img-pers:hover::after {
    background: rgb(255,151,3);
    background: linear-gradient(93deg, rgba(255,151,3,1) 0%, rgba(4,0,207,1) 100%);
    opacity: 0.7;
}

.img-overlay {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    visibility: hidden;
    font-size: 18px;
    text-align: center;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 100%;
    font-size: 22px;
    font-weight: bold;
}

.img-pers:hover .img-overlay {
    opacity: 1;
    visibility: visible;
}

.metier{
    top: 80%;
    font-size: 17px;
}
  