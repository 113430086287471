@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .landing-container {
        .wrapper {
            @apply flex flex-col items-center;
        }

        @screen md {
            .wrapper {
                @apply flex-row;
            }

            .img-background {
                @apply mt-0;
            }
        }
    }
}
