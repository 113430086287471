 /* Utilisé dans la page NotreOffre.jsx */
.bg-image{
    width: 100%;
    background-image: url(../images/kairn-modified.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(1.1) ;
}

.anim-fleche{
    animation: bouger-fleche 1s ease-in-out infinite;
}
    
@keyframes bouger-fleche {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}
